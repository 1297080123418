import React, { memo, useCallback, useEffect, useState } from "react";
import ButtonNext from "../../components/buttonNext";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImageInput from "../../components/imageInput";
import { DetailsInput } from "../../constants/data-forminput";
import { onboardActions } from "../../redux/actions/onboard";
import "./storeDetails.css";
import ServicesInput from "../../components/servicesInput";
import SelectionInput from "../../components/selectionInput";

import { getDistricts, getProvinces, getWards } from "../../APIs/onBoard";
import FieldInput from "../../components/fieldInput";

const FormDataDetails = () => {
  const [listProvinces, setListProvinces] = useState([]);
  const [listDistricts, setListDistricts] = useState([]);
  const [listWards, setListWards] = useState([]);
  const [province, setProvince] = useState();
  const [district, setDistrict] = useState();
  const [ward, setWard] = useState();
  const [servicesSelected, setServicesSelected] = useState([]);
  const [addBanner, setAddBanner] = useState([]);
  const [dataInput, setDataInput] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProvinces = async () => {
      const results = await getProvinces();
      setListProvinces(results);
    };
    fetchProvinces();
  }, []);
  useEffect(() => {
    if (province) {
      const fetchDistricts = async () => {
        const results = await getDistricts(province);
        setListDistricts(results);
        setDistrict("");
        setListWards([]);
        setWard("");
      };
      fetchDistricts();
    }
  }, [province]);

  useEffect(() => {
    if (district) {
      const fetchWards = async () => {
        const results = await getWards(district);
        if (results.length > 0) {
          setListWards(results);
        } else {
          setListWards([]);
          setWard("");
        }
      };
      fetchWards();
    }
  }, [district]);

  const handleInputChange = useCallback((key, value) => {
    setDataInput((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const onSubmit = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
        const valueDescription =
          document.getElementById("box-description").value;

        const cityItem = listProvinces.find(
          (item) => item.province_id === province
        );
        const districtItem = listDistricts.find(
          (item) => item.district_id === district
        );
        const wardItem = listWards.find((item) => item.ward_id === ward);

        const dataDetailsSubmit = {
          email: "",
          ...dataInput,
          banner: addBanner,
          services: servicesSelected.map((service) => service.id),
          description: valueDescription,
          city: cityItem?.province_name,
          district: districtItem?.district_name,
          ward: wardItem?.ward_name,
        };
        _submitDataDetails(dataDetailsSubmit);
      }
    },
    // eslint-disable-next-line
    [addBanner, province, district, ward, dataInput, servicesSelected]
  );

  const _submitDataDetails = useCallback(
    (dataDetailsSubmit) => {
      const missingFields = [];
      for (const [key, value] of Object.entries(dataDetailsSubmit)) {
        if (
          value === undefined ||
          value === null ||
          dataInput.address === undefined ||
          // (typeof value === "string" && value.trim() === "") ||
          (Array.isArray(value) && value.length === 0)
        ) {
          missingFields.push(key);
        }
      }

      if (missingFields.length > 0) {
        alert(`The following fields are required: ${missingFields.join(", ")}`);
        return;
      }

      if (dataInput.email && !validateEmail(dataInput.email)) {
        alert("The email address is not valid.");
        return;
      }
      dispatch(onboardActions.updateDetails(dataDetailsSubmit));
      navigate("/collections");
    },

    [dispatch, navigate, dataInput]
  );

  const renderFormDataDetails = useCallback(() => {
    return DetailsInput.map((item, index) => (
      <div key={index}>
        <FieldInput field={item} handleInputChange={handleInputChange} />
      </div>
    ));
  }, [handleInputChange]);

  return (
    <div className="container-formDataDetails">
      <form noValidate onSubmit={onSubmit} key="formDataDetails">
        <div className="formDataDetailsLeft">
          {renderFormDataDetails()}

          <div className="location-select">
            <SelectionInput
              tagName="Tỉnh / Thành Phố"
              dataLocation={listProvinces}
              keyValue="province"
              setCurrentInputValue={setProvince}
              currentInputValue={province}
            />
            <SelectionInput
              tagName="Quận / Huyện"
              dataLocation={listDistricts}
              keyValue="district"
              setCurrentInputValue={setDistrict}
              currentInputValue={district}
              disabled={!province}
            />
            <SelectionInput
              tagName="Phường / Xã"
              dataLocation={listWards}
              keyValue="ward"
              setCurrentInputValue={setWard}
              disabled={listWards.length === 0}
              currentInputValue={ward}
            />
          </div>

          <ServicesInput
            servicesSelected={servicesSelected}
            setServicesSelected={setServicesSelected}
          />
        </div>
        <div className="formDataDetailsRight">
          <textarea id="box-description" placeholder="Mô tả" rows="5" />

          <ImageInput
            fileImg={addBanner}
            setListImagesAdd={setAddBanner}
            page="banner"
          />
          <ButtonNext page="storeDetails" />
        </div>
      </form>
    </div>
  );
};

export default memo(FormDataDetails);
