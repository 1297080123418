import React from "react";
import "./fieldInput.css";

const FieldInput = ({ field, handleInputChange }) => {
  return (
    <div className="container-field-input">
      <h2>{field.title}</h2>
      <input
        type={field.type}
        required={field.require}
        placeholder={field.placeholder}
        onChange={(e) => handleInputChange(field.key, e.target.value)}
      />
    </div>
  );
};

export default FieldInput;
