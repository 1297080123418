import React, { useCallback, useEffect, useState } from "react";
import "./home.css";
import { useNavigate } from "react-router-dom";
import { getStores } from "../../APIs/onBoard";

const Home = (props) => {
  const [listImagesHome, setListImageHome] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    _getStores();
    // eslint-disable-next-line
  }, []);

  const _getStores = useCallback(async () => {
    const result = await getStores();
    let listTemp = [];
    if (result.length > 0) {
      listTemp = result.map((item) => {
        return item.collections[0].images[3];
      });
    }
    setListImageHome(listTemp);
    return;
  }, []);

  const handleSubcribe = useCallback(() => {
    navigate("/information");
  }, [navigate]);

  const RenderListImageHome = useCallback(() => {
    return (
      <div className="container-images-store">
        {listImagesHome.map((item, index) => {
          return (
            <div className="item-image" key={index}>
              <img src={item} />
            </div>
          );
        })}
      </div>
    );
  }, [listImagesHome]);

  return (
    <div className="container-home">
      <div className="home-content">
        <div className="home-content-left">
          <h1>Weddingo</h1>
          {props.isLandingPage ? (
            <button id="button-openapp" onClick={props.onOpenApp}>
              Mở ứng dụng
            </button>
          ) : (
            <div />
          )}
          <h2>Mọi thứ cần cho đám cưới của bạn</h2>
          <p>
            Nền tảng số 1 để chuẩn bị cho đám cưới của bạn. Từ việc chọn studio
            chụp ảnh, đặt tiệc cưới, thuê đồ cưới cho tới makeup, trang trí gia
            tiên, ... trên khắp Việt Nam.
          </p>
          <p>
            Trợ lý AI luôn sẵn sàng túc trực để giải đáp mọi thắc mắc về đám
            cưới cho bạn, thậm chí cả những vấn đề không phải về đám cưới.
          </p>
          <p>
            Nền tảng còn cung cấp tính năng lên danh sách những thứ cần chuẩn bị
            cho đám cưới, giúp bạn quản lí đám cưới dễ dàng.
          </p>
          {props.isLandingPage ? (
            <div />
          ) : (
            <button id="button-subscribe" onClick={handleSubcribe}>
              Đăng ký trở thành đối tác
              <img src="icon-subcibe.png" alt="icon-subcribe" />
            </button>
          )}
          {props.isLandingPage ? (
            <div />
          ) : (
            <div className="container-button-download">
              <a
                href="https://play.google.com/store/apps/details?id=vn.weddingo"
                target="_blank"
              >
                <img src="./button-googlePlay.svg" />
              </a>
              <a
                href="https://apps.apple.com/vn/app/weddingo/id6502347837"
                target="_blank"
              >
                <img src="./button-appStore.svg" />
              </a>
            </div>
          )}
        </div>
        <div className="home-content-right">
          <img id="backgroundHome" src="backgroundHome.svg" />
          {RenderListImageHome()}
        </div>
        <img id="logo-weddingo" src="icon-weddingo.png" alt="icon-weddingo" />
      </div>
    </div>
  );
};

export default Home;
