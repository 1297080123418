// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-selection-input h2 {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: var(--text);
  letter-spacing: 1px;
  margin-left: 1rem;
  white-space: nowrap;
}

select {
  background: transparent;
  width: 100%;
  border-radius: 5rem;
  padding: 1rem 1.4rem;
  color: var(--text);
  font-weight: 500;
  font-size: 1rem;
  outline: none;
  border: none; /* Remove default styling in WebKit browsers */ /* Remove default styling in Firefox */
  appearance: none;
}

.selection-input {
  position: relative;
  display: flex;
  align-items: center;
  background: linear-gradient(
    to right,
    rgba(212, 212, 212, 0.4) 50%,
    rgba(210, 210, 210, 0.4)
  );
  border-radius: 5rem;
  padding-right: 1rem;
}

.icon-dropdown {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0.55rem;
  margin-right: 1rem;
}

.icon-disable {
  opacity: 0.3;
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
  .container-selection-input h2 {
    margin-left: 0.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/selectionInput/selectionInput.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,YAAY,EACc,8CAA8C,EACjD,sCAAsC;EAC7D,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB;;;;GAIC;EACD,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,QAAQ;EACR,2BAA2B;EAC3B,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;AACA;;AAEA;AACA;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".container-selection-input h2 {\n  font-size: 1.2rem;\n  font-weight: 500;\n  margin-bottom: 0.5rem;\n  color: var(--text);\n  letter-spacing: 1px;\n  margin-left: 1rem;\n  white-space: nowrap;\n}\n\nselect {\n  background: transparent;\n  width: 100%;\n  border-radius: 5rem;\n  padding: 1rem 1.4rem;\n  color: var(--text);\n  font-weight: 500;\n  font-size: 1rem;\n  outline: none;\n  border: none;\n  -webkit-appearance: none; /* Remove default styling in WebKit browsers */\n  -moz-appearance: none; /* Remove default styling in Firefox */\n  appearance: none;\n}\n\n.selection-input {\n  position: relative;\n  display: flex;\n  align-items: center;\n  background: linear-gradient(\n    to right,\n    rgba(212, 212, 212, 0.4) 50%,\n    rgba(210, 210, 210, 0.4)\n  );\n  border-radius: 5rem;\n  padding-right: 1rem;\n}\n\n.icon-dropdown {\n  position: absolute;\n  right: 0;\n  top: 50%;\n  transform: translateY(-50%);\n  width: 0.55rem;\n  margin-right: 1rem;\n}\n\n.icon-disable {\n  opacity: 0.3;\n}\n\n@media screen and (max-width: 1024px) {\n}\n\n@media screen and (max-width: 768px) {\n}\n\n@media screen and (max-width: 480px) {\n  .container-selection-input h2 {\n    margin-left: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
