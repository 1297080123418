import React, { memo, useCallback, useEffect, useRef, useState } from "react";

import "./servicesInput.css";
import { getServices } from "../../APIs/onBoard";

const ServicesInput = ({ servicesSelected, setServicesSelected }) => {
  const [showBoxOptions, setShowBoxOptions] = useState(false);
  const [listServices, setListServices] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    const _getServices = async () => {
      const resultGetServices = await getServices();
      setListServices(resultGetServices);
    };
    _getServices();
  }, []);

  useEffect(() => {
    const handleScrollToBottom = () => {
      const divBoxServicesSelected = document.getElementById(
        "boxServicesSelected"
      );
      if (divBoxServicesSelected) {
        divBoxServicesSelected.scrollTop = divBoxServicesSelected.scrollHeight;
      }
    };
    handleScrollToBottom();
  }, [servicesSelected]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!containerRef.current.contains(event.target)) {
        setShowBoxOptions(false);
      }
    };

    if (showBoxOptions) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showBoxOptions]);

  const handleShowBoxOptions = useCallback(() => {
    setShowBoxOptions(!showBoxOptions);
  }, [showBoxOptions]);

  const handleSelectService = useCallback(
    (service) => {
      const checkServices = servicesSelected.find(
        (item) => service.id === item.id
      );

      if (checkServices) {
        return;
      }
      setServicesSelected([...servicesSelected, service]);
    },
    [servicesSelected, setServicesSelected]
  );

  const handleDeleteService = useCallback(
    (index) => {
      const newListServiceSelected = servicesSelected.filter(
        (_service, idx) => idx !== index
      );
      setServicesSelected(newListServiceSelected);
    },
    [servicesSelected, setServicesSelected]
  );

  const handleDeleteServicesSelected = useCallback(() => {
    setServicesSelected([]);
    // eslint-disable-next-line
  }, []);

  const RenderBoxOptions = useCallback(() => {
    return (
      <div className="container-boxOptions">
        {listServices.map((service, index) => {
          const isSelected = servicesSelected.some(
            (item) => item.id === service.id
          );
          return (
            <div
              className={`item-service ${
                isSelected
                  ? "service-selected-disabled"
                  : "item-service-available"
              }`}
              key={index}
              id={service.id}
              onClick={() => !isSelected && handleSelectService(service)}
            >
              <img
                className="image-service"
                src={service.images[0]}
                alt={service.name}
              />
              {service.name}
            </div>
          );
        })}
      </div>
    );
  }, [listServices, handleSelectService, servicesSelected]);

  const RenderSelectedServices = useCallback(() => {
    return (
      <div className="box-servicesSelected" id="boxServicesSelected">
        {servicesSelected.map((service, index) => (
          <div className="service-selected" key={index}>
            <p>{service.name}</p>
            <img
              className="icon-delete-service"
              src="iconX.svg"
              onClick={() => handleDeleteService(index)}
            />
          </div>
        ))}
      </div>
    );
  }, [servicesSelected, handleDeleteService]);

  return (
    <div className="container-services-input" ref={containerRef}>
      <div className="services-input">
        <h2>Dịch vụ cưới hỏi</h2>
        <div className="box-input-services" onClick={handleShowBoxOptions}>
          <input type="button" value="Chọn các dịch vụ mà bạn cung cấp" />
          {servicesSelected.length > 0 ? (
            <button
              onClick={handleDeleteServicesSelected}
              className="button-clearAllService"
            >
              Xóa
            </button>
          ) : (
            <img className="icon-dropdown" src="icon-dropdown.svg" />
          )}
        </div>
        {servicesSelected.length > 0 && RenderSelectedServices()}
        {showBoxOptions && RenderBoxOptions()}
      </div>
    </div>
  );
};

export default memo(ServicesInput);
