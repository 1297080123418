import React from "react";
import "./storeInfor.css";
import FormDataInfor from "./formDataInfor";
import Header from "../../components/header";
import Footer from "../../components/footer";

const InforPage = () => {
  return (
    <div className="container-infor">
      <Header step={1} />
      <div className="main-infor">
        <FormDataInfor />
      </div>
      <Footer />
    </div>
  );
};

export default InforPage;
