import { Route, Routes } from "react-router-dom";
import "./App.css";

import InforPage from "./pages/storeInfor";
import Home from "./pages/home";
import StoreDetails from "./pages/storeDetails";
import StoreCollections from "./pages/storeCollections";
import PrivacyPolicy from "./pages/privacyPolicy";
import DeleteAccountGuide from "./pages/deleteAccountGuide";
import Landing from "./pages/landing";
import VerificationPage from "./pages/verificationPage";
import SubmitStatus from "./pages/submitStatus";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/information" element={<InforPage />} />
        <Route path="/verification" element={<VerificationPage />} />
        <Route path="/store-details" element={<StoreDetails />} />
        <Route path="/collections" element={<StoreCollections />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/delete-account-guide" element={<DeleteAccountGuide />} />
        <Route path="/invitation" element={<Landing />} />
        <Route path="/submit-status" element={<SubmitStatus />} />
      </Routes>
    </div>
  );
}

export default App;
