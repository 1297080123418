import React, { memo, useCallback, useRef } from "react";
import "./imageInput.css";
import { useDispatch } from "react-redux";
import { onboardActions } from "../../redux/actions/onboard";

const ImageInput = ({ fileImg, setListImagesAdd, page }) => {
  const inputRef = useRef(null);

  const dispatch = useDispatch();

  const handleUploadImg = useCallback(
    (e) => {
      const arrTemp = Array.from(e.target.files);
      let arrFileList;
      if (arrTemp.length > 10) {
        alert(`Only 10 files are allowed to upload.`);
        const trimmedArrList = arrTemp.splice(0, 10);
        arrFileList = trimmedArrList;
      } else {
        arrFileList = arrTemp;
      }
      setListImagesAdd(arrFileList);

      inputRef.current.value = "";
    },
    [setListImagesAdd]
  );

  const handleDelImg = useCallback(
    (index) => {
      const newFile = fileImg.filter((_item, idx) => idx !== index);
      dispatch(onboardActions.updateBanner(newFile));
      setListImagesAdd(newFile);
    },
    [fileImg, dispatch, setListImagesAdd]
  );

  const RenderConUpload = useCallback(() => {
    if (fileImg.length > 0) {
      return fileImg.map((item, index) => {
        return (
          <div className="main-file-upload" key={index}>
            <div className="upload-img">
              <img src={URL.createObjectURL(item)} alt="no" />
              <span>{item.name}</span>
            </div>
            <button onClick={() => handleDelImg(index)}>
              <img src="./icon-delete.png" alt="icon-delete" />
            </button>
          </div>
        );
      });
    }
  }, [fileImg, handleDelImg]);

  return (
    <div className="container-imageinput">
      <div className="main-imageinput">
        <label className="inputContainer">
          <input
            type="file"
            accept="image/*"
            multiple={page === "collection"}
            ref={inputRef}
            onChange={handleUploadImg}
          />

          <img src="./icon-upload.png" alt="icon-upload" />
          <h2>
            {page === "banner"
              ? "Tải lên Banner"
              : "Tải lên Bộ sưu tập của bạn (Tối đa 3 bộ sưu tập)"}
          </h2>
          <h3>
            {page === "banner"
              ? "Kéo và thả tệp vào đây"
              : "Kéo và thả các tệp vào đây"}
          </h3>
        </label>
        <div className="container-upload" id={page}>
          {RenderConUpload()}
        </div>
      </div>
    </div>
  );
};

export default memo(ImageInput);
