import { memo, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { deviceDetect } from "react-device-detect";
import Home from "../home";

const Landing = () => {
  const deviceInfo = deviceDetect(window.navigator.userAgent);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    _init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _init = useCallback(async () => {
    const referralCode = searchParams.get("referralCode");
    const screenInfo = window.screen;

    const res = await fetch("https://geolocation-db.com/json/");
    const data = await res.json();
    const identifier = `${data.IPv4}-${data.latitude}-${data.longitude}-${deviceInfo.os}-${screenInfo.width}-${screenInfo.height}`;

    fetch(
      "https://weddingo.vn/api/authentication/referral/update-todo-invitation",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          referralCode: referralCode,
          userAgent: identifier,
        }),
      }
    );
  }, [deviceInfo, searchParams]);

  const onClick = useCallback(async () => {
    try {
      const timeout = setTimeout(
        () => {
          if (deviceInfo.os === "Android" && !document.hasFocus()) {
            return;
          }

          if (deviceInfo.os === "iOS") {
            window.location.replace(
              "itms-apps://itunes.apple.com/app/id6502347837"
            );
          }

          if (deviceInfo.os === "Android") {
            window.location.replace(
              "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=vn.weddingo"
            );
          }

          clearTimeout(timeout);
        },
        deviceInfo.os === "iOS" ? 25 : 100
      );

      const referralCode = searchParams.get("referralCode");

      if (deviceInfo.os === "iOS") {
        window.location = `vn.weddingo://invitation?referralCode=${referralCode}`;
      }

      if (deviceInfo.os === "Android") {
        window.location = `vn.weddingo://invitation?referralCode=${referralCode}`;
      }
    } catch (e) {
      console.log(e);
    }
  }, [deviceInfo, searchParams]);

  return <Home isLandingPage={true} onOpenApp={onClick} />;
};

export default memo(Landing);
