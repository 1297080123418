import React, { memo } from "react";
import "./buttonNext.css";

const ButtonNext = ({ page }) => {
  return (
    <div className="container-button" key={page}>
      <button id={page} type="submit">
        Tiếp theo
      </button>
    </div>
  );
};

export default memo(ButtonNext);
