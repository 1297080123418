import { createAction, mapType, status } from "..";

export const types = {
  UPDATE_INFOR: mapType("UPDATE_INFOR", status.start),
  UPDATE_DETAILS: mapType("UPDATE_DETAILS", status.start),

  UPDATE_COLLECTIONS: mapType("UPDATE_COLLECTIONS", status.start),
  UPDATE_BANNER: mapType("UPDATE_BANNER", status.start),
};

export const onboardActions = {
  updateInfor: createAction(types.UPDATE_INFOR),
  updateDetails: createAction(types.UPDATE_DETAILS),

  updateCollections: createAction(types.UPDATE_COLLECTIONS),
  updateBanner: createAction(types.UPDATE_BANNER),
};
