import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { onboardActions } from "../../redux/actions/onboard";
import "./renderListCollections.css";

const RenderListCollections = ({ dataCollections }) => {
  const dispatch = useDispatch();
  const handleDeleteCollection = useCallback(
    (collectionIndex) => {
      const newListCollections = dataCollections.filter(
        (_item, idx) => idx !== collectionIndex
      );
      dispatch(onboardActions.updateCollections(newListCollections));
    },
    [dataCollections, dispatch]
  );

  const handleDeleteImgOfCollection = useCallback(
    (collectionIndex, imageIndex) => {
      const updatedCollections = dataCollections
        .map((collection, idx) => {
          if (idx === collectionIndex) {
            const newImages = collection.images.filter(
              (_item, idx) => idx !== imageIndex
            );
            if (newImages.length === 0) {
              return null;
            } else {
              return {
                title: collection.title,
                images: newImages,
              };
            }
          } else {
            return collection;
          }
        })
        .filter(Boolean);

      dispatch(onboardActions.updateCollections(updatedCollections));
    },
    [dataCollections, dispatch]
  );

  return dataCollections[0]
    ? dataCollections.map((collection, collectionIndex) => (
        <div key={collectionIndex} className="show-collections">
          <div className="title-collection">
            <h3>{collection.title}</h3>
            <button onClick={() => handleDeleteCollection(collectionIndex)}>
              <img src="icon-delete.png" />
            </button>
          </div>
          <div className="container-images">
            {collection.images.map((image, imageIndex) => (
              <div className="image-of-collection" key={imageIndex}>
                <img src={URL.createObjectURL(image)} />
                <button
                  onClick={() =>
                    handleDeleteImgOfCollection(collectionIndex, imageIndex)
                  }
                >
                  <img src="iconX.svg" />
                </button>
              </div>
            ))}
          </div>
        </div>
      ))
    : null;
};

export default RenderListCollections;
