const status = {
  start: {
    valuePrefix: "@@FETCH_START/",
    keyPostfix: "_START",
  },
  success: {
    valuePrefix: "@@FETCH_SUCCESS/",
    keyPostfix: "_SUCCESS",
  },
  error: {
    valuePrefix: "@@FETCH_ERROR/",
    keyPostfix: "_ERROR",
  },
};
//Recreate type to know whether it is loading, error or success
const mapType = (type, status) => `${status.valuePrefix}${type}`;
//HOF create action
const createAction =
  (type) =>
  (payload = {}, onSuccess = () => {}, onError = () => {}) => {
    return {
      type,
      payload,
      onSuccess,
      onError,
    };
  };
//Initial state for reducer factory
const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: null,
};
//Create reducer for each action which is needed to check loading
const reducerFactory =
  ({ onStart, onSuccess, onError }) =>
  (state = initialState, { type, payload }) => {
    switch (type) {
      case onStart:
        return {
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: null,
        };
      case onSuccess:
        return {
          isLoading: false,
          isSuccess: true,
          isError: false,
          error: null,
        };
      case onError:
        return {
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: payload,
        };
      case "CANCEL_TASK":
        return {
          isLoading: false,
          isSuccess: false,
          isError: false,
          error: null,
        };
      default:
        return state;
    }
  };
export { status, mapType, createAction, reducerFactory };
