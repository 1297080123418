import React, { memo, useCallback, useState } from "react";
import ButtonNext from "../../components/buttonNext";

import { useDispatch } from "react-redux";

import "./storeInfor.css";
import { useNavigate } from "react-router-dom";
import { onboardActions } from "../../redux/actions/onboard";
import RangeSlider from "../../components/rangeSlider";
import FieldInput from "../../components/fieldInput";
import { InforInput } from "../../constants/data-forminput";

const FormDataInfor = () => {
  const [dataInput, setDataInput] = useState([]);
  const [alertPrice, setAlertPrice] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitDataInfor = useCallback(() => {
    const missingFields = [];
    if (!dataInput.name) {
      missingFields.push("Tên");
    }
    if (!dataInput.mobile) {
      missingFields.push("Số điện thoại");
    }

    if (missingFields.length > 0) {
      alert(`The following fields are required: ${missingFields.join(", ")}`);
      return;
    }
    dispatch(onboardActions.updateInfor(dataInput));
    navigate("/verification");
  }, [dataInput, dispatch, navigate]);

  const handleInputChange = useCallback((key, value) => {
    setDataInput((currentValue) => ({
      ...currentValue,
      [key]: value,
    }));
  }, []);

  const onSubmit = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
        if (
          dataInput.max_prices &&
          dataInput.min_prices &&
          dataInput.max_prices > dataInput.min_prices &&
          dataInput.min_prices >= 0
        ) {
          setAlertPrice(false);
        } else {
          setAlertPrice(true);
          return;
        }
        submitDataInfor();
      }
    },
    [submitDataInfor, dataInput]
  );

  const RenderFormDataInfor = useCallback(() => {
    return InforInput.map((field, index) => {
      return (
        <div className="item-field-dataInfor" key={index}>
          <FieldInput field={field} handleInputChange={handleInputChange} />
        </div>
      );
    });
  }, [handleInputChange]);

  return (
    <div className="container-data-infor">
      <form noValidate onSubmit={onSubmit} key="formDataInfor">
        {RenderFormDataInfor()}
        <RangeSlider
          handleInputChange={handleInputChange}
          alertPrice={alertPrice}
        />
        <ButtonNext page="storeInfor" />
      </form>
    </div>
  );
};

export default memo(FormDataInfor);
