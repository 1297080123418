// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-footer {
  width: 100%;
  height: 10rem;
  background: var(--lightPrimary2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1rem;
  color: var(--background);
  font-size: 1.1rem;
  letter-spacing: 2px;
  z-index: 1;
}

.container-footer img {
  height: 60%;
  align-self: flex-end;
}

a:link {
  text-decoration: none;
  padding: 0 0.5rem;
  line-height: 1.5rem;
  font-size: 0.75rem;
  color: white;
}

a:visited {
  color: white;
}

a:focus,
a:hover {
  border-bottom: 1px solid;
}

a:active {
  color: var(--textTitle);
}

@media screen and (max-width: 480px) {
  .container-footer {
    height: 7rem;
    padding: 1rem;
    flex-direction: row-reverse;
  }
  .container-footer img {
    height: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,gCAAgC;EAChC,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,qBAAqB;EACrB,aAAa;EACb,wBAAwB;EACxB,iBAAiB;EACjB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE,wBAAwB;AAC1B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE;IACE,YAAY;IACZ,aAAa;IACb,2BAA2B;EAC7B;EACA;IACE,YAAY;EACd;AACF","sourcesContent":[".container-footer {\n  width: 100%;\n  height: 10rem;\n  background: var(--lightPrimary2);\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: flex-end;\n  padding: 1rem;\n  color: var(--background);\n  font-size: 1.1rem;\n  letter-spacing: 2px;\n  z-index: 1;\n}\n\n.container-footer img {\n  height: 60%;\n  align-self: flex-end;\n}\n\na:link {\n  text-decoration: none;\n  padding: 0 0.5rem;\n  line-height: 1.5rem;\n  font-size: 0.75rem;\n  color: white;\n}\n\na:visited {\n  color: white;\n}\n\na:focus,\na:hover {\n  border-bottom: 1px solid;\n}\n\na:active {\n  color: var(--textTitle);\n}\n\n@media screen and (max-width: 480px) {\n  .container-footer {\n    height: 7rem;\n    padding: 1rem;\n    flex-direction: row-reverse;\n  }\n  .container-footer img {\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
