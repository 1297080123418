import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";

import "./storeCollections.css";
import { onboardActions } from "../../redux/actions/onboard";
import {
  // submitStoreInformation,
  uploadBanner,
  uploadCollections,
} from "../../APIs/onBoard";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Loading from "../../components/loading";
import LocationSharePopup from "../../components/locationSharePopup";
import useGeolocation from "../../hooks/useGeolocation";
import AddCollectionBox from "./addCollectionBox";
import RenderListCollections from "../../components/renderListCollections";

const StoreCollections = () => {
  const { dataCollections = [] } = useSelector((state) => state.onboard);
  const { dataInfor } = useSelector((state) => state.onboard);
  const { dataDetails } = useSelector((state) => state.onboard);
  const [listImagesAdd, setListImagesAdd] = useState([]);
  const [titleCollection, setTitleCollection] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPopupLocation, setShowPopupLocation] = useState(false);
  const [geoLocation, requestGeoLocation] = useGeolocation();

  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAddCollection = useCallback(() => {
    const newCollection = {
      title: titleCollection,
      images: listImagesAdd,
    };
    const newDataCollection = [...dataCollections, newCollection];
    dispatch(onboardActions.updateCollections(newDataCollection));
    setListImagesAdd([]);
    setTitleCollection("");
  }, [dispatch, listImagesAdd, titleCollection, dataCollections]);

  const _uploadBanner = useCallback(async (bodyBanner) => {
    const result = await uploadBanner(bodyBanner);
    return result;
  }, []);

  const _uploadCollection = useCallback(
    async (idStore) => {
      const uploadResults = [];

      for (const collection of dataCollections) {
        const formData = new FormData();

        collection.images.forEach((image) => {
          formData.append("collections", image);
        });
        formData.append("id", idStore);

        const result = await uploadCollections(formData);

        uploadResults.push(result);
      }

      const updatedCollections = dataCollections.map((collection, index) => ({
        images: uploadResults[index],
        title: collection.title,
      }));

      return updatedCollections;
    },
    [dataCollections]
  );

  const _submitStoreInformation = useCallback(
    async (idStore, banner, collection) => {
      const valueOnboardSubmit = {
        id: idStore,
        ...dataInfor,
        ...dataDetails,
        ...geoLocation,
        banner,
        collections: collection,
      };
      // const result = submitStoreInformation(JSON.stringify(valueOnboardSubmit));
      return valueOnboardSubmit;
    },
    [dataDetails, dataInfor, geoLocation]
  );

  const handleSubmitOnboard = useCallback(async () => {
    if (geoLocation.lat === null || geoLocation.long === null) {
      setShowPopupLocation(true);
      return;
    }

    setIsLoading(true);

    const bodyBanner = new FormData();
    const bannerFile = dataDetails.banner[0];

    bodyBanner.append("image", bannerFile);

    console.log(bodyBanner);
    try {
      const bannerResult = await _uploadBanner(bodyBanner);
      console.log("Banner uploaded:", bannerResult);

      const collectionResult = await _uploadCollection(bannerResult.id);
      console.log("Collections uploaded:", collectionResult);

      const submitResult = await _submitStoreInformation(
        bannerResult.id,
        bannerResult.url,
        collectionResult
      );
      console.log("Store information submitted:", submitResult);
    } catch (error) {
      console.error("Error occurred during onboarding:", error);
      return;
    } finally {
      setIsLoading(false);
      // navigate("/submit-status");
    }
  }, [
    _uploadBanner,
    _uploadCollection,
    _submitStoreInformation,
    dataDetails,
    // navigate,
    geoLocation,
  ]);

  const handleShareLocation = (e) => {
    setShowPopupLocation(false);
    if (e) {
      requestGeoLocation(e);
    } else {
      requestGeoLocation();
    }
  };

  return (
    <div className="container-collections">
      <Header step={4} />
      <div className="main-collections">
        {isLoading && <Loading />}
        {showPopupLocation && (
          <LocationSharePopup
            onClose={() => setShowPopupLocation(false)}
            onShareLocation={handleShareLocation}
          />
        )}
        <div className="box-addcollection">
          <AddCollectionBox
            setListImagesAdd={setListImagesAdd}
            listImagesAdd={listImagesAdd}
            setTitleCollection={setTitleCollection}
            titleCollection={titleCollection}
            handleAddCollection={handleAddCollection}
          />

          {dataCollections[0] && (
            <>
              <RenderListCollections dataCollections={dataCollections} />
              <button onClick={handleSubmitOnboard} id="buttonNext">
                Hoàn tất
              </button>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StoreCollections;
