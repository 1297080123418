// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__s9ceb {
  padding: 30px;
  justify-content: center;
}

.styles_image_guide__fy6-9 {
  width: auto;
  height: 400px;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/deleteAccountGuide/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".container {\n  padding: 30px;\n  justify-content: center;\n}\n\n.image_guide {\n  width: auto;\n  height: 400px;\n  margin-left: 30px;\n  margin-top: 10px;\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__s9ceb`,
	"image_guide": `styles_image_guide__fy6-9`
};
export default ___CSS_LOADER_EXPORT___;
