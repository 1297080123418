const newHeaders = new Headers({
  Accept: "application/json",
});

const apiUrl = "https://weddingo.vn/api/";

const fetchRequest = async (method, url, params) => {
  const isFormData = params instanceof FormData;

  const requestOptions = {
    method: method,
    headers: isFormData
      ? newHeaders
      : new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
    body: method !== "GET" && params ? params : undefined,
  };
  let fullUrl = `${apiUrl}${url}`;

  if (params && method === "GET") {
    const paramSearch = new URLSearchParams(params).toString();
    fullUrl = `${apiUrl}${url}?${paramSearch}`;
  }

  try {
    const response = await fetch(fullUrl, requestOptions);
    if (response.status < 400) {
      const data = await response.json();
      return data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export default fetchRequest;
