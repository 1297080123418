// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-share-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8888;
}

.popup-content {
  padding: 1rem 1rem 0;
  background-color: var(--card);
  border-radius: 0.5rem;
  text-align: center;
  width: 32rem;
  z-index: 9999;
  animation: fadeInScale 0.2s ease-in-out;
}

.popup-content img {
  width: 2rem;
}

.popup-content h3 {
  font-size: 1.4rem;
  color: var(--text);
}

.popup-content p {
  font-size: 1rem;
  color: var(--text);
}

.popup-options {
  margin-top: 1rem;
  border-top: solid 1px var(--border);
  display: flex;
  justify-content: space-evenly;
}

.popup-options button {
  width: 40%;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: var(--text);
  border-radius: 0.5rem;
  cursor: pointer;
}

.popup-options button:hover {
  background-color: var(--lightPrimary2);
  opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/components/locationSharePopup/LocationSharePopup.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,oBAAoB;EACpB,6BAA6B;EAC7B,qBAAqB;EACrB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,uCAAuC;AACzC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mCAAmC;EACnC,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,UAAU;EACV,cAAc;EACd,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,sCAAsC;EACtC,YAAY;AACd","sourcesContent":[".location-share-popup {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 8888;\n}\n\n.popup-content {\n  padding: 1rem 1rem 0;\n  background-color: var(--card);\n  border-radius: 0.5rem;\n  text-align: center;\n  width: 32rem;\n  z-index: 9999;\n  animation: fadeInScale 0.2s ease-in-out;\n}\n\n.popup-content img {\n  width: 2rem;\n}\n\n.popup-content h3 {\n  font-size: 1.4rem;\n  color: var(--text);\n}\n\n.popup-content p {\n  font-size: 1rem;\n  color: var(--text);\n}\n\n.popup-options {\n  margin-top: 1rem;\n  border-top: solid 1px var(--border);\n  display: flex;\n  justify-content: space-evenly;\n}\n\n.popup-options button {\n  width: 40%;\n  margin: 0.5rem;\n  padding: 0.5rem 1rem;\n  font-size: 1rem;\n  color: var(--text);\n  border-radius: 0.5rem;\n  cursor: pointer;\n}\n\n.popup-options button:hover {\n  background-color: var(--lightPrimary2);\n  opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
