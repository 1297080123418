import React from "react";
import "./LocationSharePopup.css";

const LocationSharePopup = ({ onClose, onShareLocation }) => {
  return (
    <div className="location-share-popup" onClick={onClose}>
      <div className="popup-content">
        <img src="logo-map.svg" alt="Location Icon" />
        <h3>Cho phép Weddingo truy cập vị trí của bạn?</h3>
        <p>
          Weddingo muốn cải thiện trải nghiệm người dùng bằng cách cung cấp dịch
          vụ tùy chỉnh dựa trên vị trí của bạn.
        </p>

        <div className="popup-options">
          <button onClick={() => onShareLocation(false)}>Không, cảm ơn</button>
          <button onClick={() => onShareLocation(true)}>Chia sẻ vị trí</button>
        </div>
      </div>
    </div>
  );
};

export default LocationSharePopup;
