// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-infor {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.main-infor {
  background-color: white;
  box-shadow: 0 0 2px var(--card);
  border-radius: 1rem;
  padding: 5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
}

.main-infor p {
  font-size: 2rem;
  font-weight: 500;
}

.container-data-infor {
  border-radius: 0.5rem;
  width: 90%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.container-data-infor form {
  margin: 2rem 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
}

.item-field-dataInfor {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .main-infor {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
}
`, "",{"version":3,"sources":["webpack://./src/pages/storeInfor/storeInfor.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,+BAA+B;EAC/B,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,WAAW;EACX,wBAAmB;EAAnB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,SAAS;AACX;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;AACA;;AAEA;AACA","sourcesContent":[".container-infor {\n  height: 100vh;\n  width: 100vw;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.main-infor {\n  background-color: white;\n  box-shadow: 0 0 2px var(--card);\n  border-radius: 1rem;\n  padding: 5rem;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  width: 100%;\n  height: fit-content;\n}\n\n.main-infor p {\n  font-size: 2rem;\n  font-weight: 500;\n}\n\n.container-data-infor {\n  border-radius: 0.5rem;\n  width: 90%;\n  max-width: 40rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 1.5rem;\n}\n\n.container-data-infor form {\n  margin: 2rem 0;\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  gap: 1rem;\n}\n\n.item-field-dataInfor {\n  width: 100%;\n}\n\n@media screen and (max-width: 1024px) {\n  .main-infor {\n    padding: 0;\n  }\n}\n\n@media screen and (max-width: 768px) {\n}\n\n@media screen and (max-width: 480px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
