import { memo } from "react";
import styles from "./styles.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <h1>Chính sách bảo mật</h1>
      <br />
      <h2>Giới thiệu</h2>
      <br />
      <li>
        Chúng tôi hiểu rằng bạn nhận thức và quan tâm đến lợi ích riêng tư cá
        nhân của bạn và chúng tôi coi trọng điều đó. Thông báo về Quyền riêng tư
        này nhằm giúp bạn hiểu dữ liệu nào chúng tôi thu thập, lý do chúng tôi
        thu thập, chúng tôi làm gì với dữ liệu đó và cũng quy định các quyền
        riêng tư của bạn. Chúng tôi nhận ra rằng bảo mật thông tin là trách
        nhiệm liên tục và vì vậy, chúng tôi sẽ cập nhật Thông báo về quyền riêng
        tư này theo thời gian khi chúng tôi thực hiện các thực hành dữ liệu cá
        nhân mới hoặc áp dụng các chính sách bảo mật mới. Cách chúng tôi thu
        thập và sử dụng (xử lý) thông tin cá nhân của bạn:
      </li>
      <br />
      <li>
        weddingo.vn thu thập thông tin cá nhân về những người dùng đã đăng ký và
        những khách hàng khác. Chúng tôi sử dụng thông tin này để nâng cao trải
        nghiệm của bạn trên trang web của chúng tôi và cung cấp dịch vụ cho bạn.
        Các loại thông tin chúng tôi thu thập có thể khác nhau, tùy thuộc vào
        cách bạn chọn sử dụng trang web của chúng tôi. Chúng tôi không bán thông
        tin cá nhân của bạn cho bất kỳ ai.
      </li>
      <br />
      <h3>Mở rộng:</h3>
      <p>Thông tin cá nhân bạn cung cấp cho chúng tôi:</p>
      <br />
      <h3>Đăng ký</h3>
      <li>
        Khi bạn đăng ký và tạo tài khoản trên trang web của chúng tôi bằng hồ sơ
        mạng xã hội hiện có của mình, chúng tôi sẽ thu thập thông tin từ hồ sơ
        của bạn, bao gồm (nhưng không giới hạn) biệt hiệu, ảnh đại diện (ảnh),
        giới tính, ngày sinh, ID hồ sơ hoặc URL. Người dùng có thể chỉnh sửa hồ
        sơ của mình bất kỳ lúc nào để thay đổi, thêm hoặc xóa một số thông tin
        cá nhân nhất định.
      </li>
      <br />
      <h3>Thư từ của bạn với weddingo.vn</h3>
      <li>
        Nếu bạn trao đổi với chúng tôi qua email, dịch vụ bưu chính hoặc hình
        thức liên lạc khác, chúng tôi có thể giữ lại thư từ đó và thông tin có
        trong đó và sử dụng nó để trả lời câu hỏi của bạn hoặc để lưu hồ sơ về
        khiếu nại, yêu cầu chỗ ở của bạn, và những thứ tương tự. Nếu bạn muốn
        weddingo.vn “xóa” thông tin cá nhân của bạn hoặc không cho phép liên lạc
        với bạn, vui lòng liên hệ với chúng tôi tại nguyenduythang811@gmail.com
      </li>
      <br />
      <h3>Mục đích xử lý dữ liệu của bạn</h3>
      <li>
        Như đã giải thích ở trên, weddingo.vn xử lý dữ liệu của bạn để nâng cao
        trải nghiệm của bạn trên trang web của chúng tôi và cung cấp cho bạn các
        dịch vụ mà bạn yêu cầu từ chúng tôi. Bằng cách tạo tài khoản, bạn có
        quyền truy cập vào các tính năng bổ sung, chẳng hạn như khả năng ghi chú
        những danh mục đám cưới của mình, nhận thông báo về nội dung mới, v.v.
      </li>
      <br />
      <h3>Sử dụng ứng dụng weddingo</h3>
      <li>
        Như hầu hết các ứng dụng khác, weddingo.vn tự động thu thập một số thông
        tin nhất định và lưu trữ trong nhật ký máy chủ. Thông tin có thể bao gồm
        địa chỉ giao thức internet (IP) và loại thiết bị của bạn. Chúng tôi có
        thể sử dụng thông tin này và các thông tin khác để giúp chẩn đoán sự cố
        với máy chủ và quản trị trang web của chúng tôi. weddingo cũng sử dụng
        các công cụ phân tích Google Analytics để phân tích sâu hơn và được sử
        dụng để theo dõi các tương tác của bạn với ứng dụng của chúng tôi nhằm
        giúp chúng tôi phân tích xu hướng, theo dõi chuyển động của khách truy
        cập và thu thập thông tin nhân khẩu học rộng rãi hỗ trợ chúng tôi trong
        việc xác định sở thích của khách truy cập.
      </li>
      <br />
      <h3>Khi nào và cách chúng tôi chia sẻ thông tin với người khác</h3>
      <li>
        Thông tin cá nhân mà weddingo thu thập từ bạn được lưu trữ trong một
        hoặc nhiều cơ sở dữ liệu do các bên thứ ba lưu trữ trên các máy chủ đặt
        tại Singapore. Các bên thứ ba này không sử dụng hoặc có quyền truy cập
        vào thông tin cá nhân của bạn cho bất kỳ mục đích nào khác ngoài việc
        lưu trữ và truy xuất. Chúng tôi không tiết lộ dữ liệu cá nhân của bạn
        cho bên thứ ba để họ sử dụng độc lập trừ khi: (1) bạn yêu cầu hoặc cho
        phép; (2) thông tin được cung cấp để tuân thủ luật pháp (ví dụ: tuân thủ
        lệnh khám xét, trát đòi hầu tòa hoặc lệnh tòa), thực thi thỏa thuận mà
        chúng tôi có với bạn hoặc để bảo vệ quyền, tài sản hoặc sự an toàn của
        chúng tôi hoặc các quyền , tài sản hoặc sự an toàn của nhân viên của
        chúng tôi hoặc những người khác; (3) thông tin được cung cấp cho các đại
        lý, nhà cung cấp hoặc nhà cung cấp dịch vụ của chúng tôi, những người
        thực hiện các chức năng thay mặt chúng tôi; (4) để giải quyết các tranh
        chấp, khiếu nại, hoặc với những người chứng minh quyền hợp pháp để thay
        mặt bạn hành động.
      </li>
      <br />
      <h4>1. Khi chúng ta theo đuổi lợi ích hợp pháp</h4>
      <li>
        Chúng tôi xử lý thông tin của bạn vì lợi ích hợp pháp của chúng tôi và
        của các bên thứ ba trong khi áp dụng các biện pháp bảo vệ thích hợp để
        bảo vệ quyền riêng tư của bạn.
      </li>
      <li>
        Điều này có nghĩa là chúng tôi xử lý thông tin của bạn cho những thứ
        như: Cung cấp, duy trì và cải tiến các dịch vụ của chúng tôi để đáp ứng
        nhu cầu của người dùng Phát triển các sản phẩm và tính năng mới hữu ích
        cho người dùng của chúng tôi.
      </li>
      <li>
        Hiểu cách mọi người sử dụng dịch vụ của chúng tôi để đảm bảo và cải
        thiện hiệu suất của các dịch vụ của chúng tôi.
      </li>
      <li>
        Tùy chỉnh các dịch vụ của chúng tôi để cung cấp cho bạn trải nghiệm
        người dùng tốt hơn.
      </li>
      <li>
        Phát hiện, ngăn chặn hoặc giải quyết các vấn đề gian lận, lạm dụng, bảo
        mật hoặc kỹ thuật với các dịch vụ của chúng tôi.
      </li>
      <li>
        Bảo vệ không gây tổn hại đến quyền, tài sản hoặc sự an toàn của
        weddingo, người dùng của chúng tôi hoặc công chúng theo yêu cầu hoặc
        được pháp luật cho phép.
      </li>
      <li>
        Thực hiện nghĩa vụ đối với các đối tác của chúng tôi như nhà phát triển
        và chủ sở hữu quyền.
      </li>
      <li>
        Thực thi các khiếu nại pháp lý, bao gồm cả việc điều tra các vi phạm
        tiềm ẩn đối với Điều khoản dịch vụ hiện hành.
      </li>
      <br />
      <h4>2. Khi chúng tôi cung cấp dịch vụ</h4>
      <li>
        Chúng tôi xử lý dữ liệu của bạn để cung cấp dịch vụ mà bạn đã yêu cầu
        theo hợp đồng. Ví dụ: chúng tôi thu thập bản sao giấy tờ tùy thân do
        chính phủ cấp của bạn và xử lý hệ thống thanh toán hoặc thông tin ngân
        hàng của bạn khi bạn muốn xuất bản nội dung trả phí hoặc cố gắng rút
        tiền từ số dư ảo của bạn trên trang nền tảng của chúng tôi.
      </li>
      <br />
      <h4>3. Khi chúng tôi tuân thủ các nghĩa vụ pháp lý</h4>
      <p>
        Chúng tôi sẽ xử lý dữ liệu của bạn khi chúng tôi có nghĩa vụ pháp lý
        phải làm như vậy, chẳng hạn như nếu chúng tôi đang phản hồi quy trình
        pháp lý hoặc yêu cầu có thể thực thi của chính phủ.
      </p>
      <h5>Bảo mật thông tin của bạn</h5>
      <li>
        Để giúp bảo vệ quyền riêng tư của dữ liệu và thông tin nhận dạng cá nhân
        mà bạn truyền tải thông qua việc sử dụng trang nền tảng của chúng tôi,
        chúng tôi duy trì các biện pháp bảo vệ vật lý, kỹ thuật và hành chính.
        Chúng tôi liên tục cập nhật và kiểm tra công nghệ bảo mật của mình.
        Chúng tôi hạn chế quyền truy cập vào dữ liệu cá nhân của bạn đối với
        những nhân viên cần biết thông tin đó để cung cấp lợi ích hoặc dịch vụ
        cho bạn. Ngoài ra, chúng tôi đào tạo nhân viên của mình về tầm quan
        trọng của tính bảo mật và duy trì sự riêng tư và bảo mật thông tin của
        bạn. Chúng tôi cam kết thực hiện các biện pháp kỷ luật thích hợp để thực
        thi trách nhiệm về quyền riêng tư của nhân viên.
      </li>
      <h5>Lưu trữ và lưu giữ dữ liệu</h5>
      <li>
        Dữ liệu cá nhân của bạn được weddingo lưu trữ trên (các) máy chủ chuyên
        dụng đặt tại Singapore. weddingo giữ lại dữ liệu trong suốt thời gian
        khách hàng quan hệ với weddingo, cộng thêm sáu tháng. Để biết thêm thông
        tin về nơi và thời gian lưu trữ dữ liệu cá nhân của bạn cũng như để biết
        thêm thông tin về quyền xóa và tính di động của bạn, vui lòng liên hệ
        với chúng tôi tại nguyenduythang811@gmail.com
      </li>
      <h5>Các thay đổi và cập nhật đối với Thông báo về quyền riêng tư</h5>
      <li>
        Bằng cách sử dụng weddingo, bạn đồng ý với các điều khoản và điều kiện
        có trong Thông báo về Quyền riêng tư này và / hoặc bất kỳ thỏa thuận nào
        khác mà chúng tôi có thể có với bạn. Nếu bạn không đồng ý với bất kỳ
        điều khoản và điều kiện nào, bạn không nên sử dụng ứng dụng này hoặc bất
        kỳ dịch vụ weddingo nào. Khi tổ chức, tư cách thành viên và lợi ích của
        chúng tôi thay đổi theo thời gian, Thông báo về Quyền riêng tư này cũng
        sẽ thay đổi. Chúng tôi có quyền sửa đổi Thông báo về Quyền riêng tư vào
        bất kỳ lúc nào, vì bất kỳ lý do gì, mà không cần thông báo cho bạn,
        ngoài việc đăng Thông báo Bảo mật đã sửa đổi trên trang web này. Chúng
        tôi có thể gửi email nhắc nhở định kỳ về các thông báo và điều khoản và
        điều kiện của chúng tôi và sẽ gửi email cho người dùng về những thay đổi
        quan trọng trong đó, nhưng bạn nên kiểm tra trang web của chúng tôi
        thường xuyên để xem Thông báo về quyền riêng tư hiện có hiệu lực và bất
        kỳ thay đổi nào có thể đã được thực hiện đối với thông báo đó. Các điều
        khoản trong tài liệu này thay thế tất cả các thông báo hoặc tuyên bố
        trước đây liên quan đến thực tiễn bảo mật của chúng tôi và các điều
        khoản và điều kiện chi phối việc sử dụng ứng dụng này.
      </li>
      <br />
      <h4>Câu hỏi, thắc mắc hoặc khiếu nại</h4>
      <li>Email: nguyenduythang811@gmail.com</li>
    </div>
  );
};

export default memo(PrivacyPolicy);
