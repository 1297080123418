import React from "react";

import "./footer.css";

const Footer = () => {
  return (
    <div className="container-footer">
      <a href="https://www.w3schools.com" target="_blank">
        Chính sách và quyền riêng tư
      </a>
      <img src="icon-weddingo.png" alt="logo-weddingo" />
    </div>
  );
};

export default Footer;
