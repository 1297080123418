import React from "react";

import "./submitStatus.css";
import Header from "../../components/header";
import Footer from "../../components/footer";

const SubmitStatus = () => {
  return (
    <div className="container-submitPage">
      <Header step={6} />
      <div className="main-submitPage">
        <div className="logo-success"></div>
        <h1>Thành công!</h1>
        <p>
          Weddingo đã nhận được thông tin đăng ký của bạn.
          <br />
          Weddingo sẽ phản hồi qua số điện thoại hoặc Email mà bạn đã cung cấp.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default SubmitStatus;
