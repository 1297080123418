import { useState, useCallback, useEffect } from "react";

const useGeolocation = () => {
  const [location, setLocation] = useState({
    lat: null,
    long: null,
    error: null,
  });

  const [permissionStatus, setPermissionStatus] = useState(null);

  useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        setPermissionStatus(result.state);
        if (result.state === "granted") {
          requestLocation(true);
        } else if (result.state === "denied") {
          requestLocation();
        }
        result.onchange = () => {
          setPermissionStatus(result.state);
        };
      });
    } else {
      setPermissionStatus("unsupported");
    }
    // eslint-disable-next-line
  }, []);

  const requestLocation = useCallback((e) => {
    if (e) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation({
              lat: position.coords.latitude,
              long: position.coords.longitude,
              error: null,
            });
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              setLocation({
                lat: 0,
                long: 0,
                error: "Permission denied.",
              });
            } else {
              setLocation({
                lat: null,
                long: null,
                error: "Unable to retrieve location.",
              });
            }
          }
        );
      } else {
        setLocation({
          lat: null,
          long: null,
          error: "Geolocation is not supported by this browser.",
        });
      }
    } else {
      setLocation({
        lat: 0,
        long: 0,
        error: "Permission denied.",
      });
      setPermissionStatus("denied");
    }
  }, []);

  return [location, requestLocation, permissionStatus];
};

export default useGeolocation;
