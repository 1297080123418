// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-submitPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: var(--text);
  font-size: 2rem;
}

.main-submitPage {
  width: 70%;
  height: 50%;
  border-radius: 1.5rem;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 1rem;
}

.main-submitPage p {
  text-align: center;
  font-size: 1.5rem;
}

.logo-success {
  position: relative;
  content: "";
  width: 10rem;
  height: 10rem;
  padding: 1rem;
  border-radius: 50%;
  box-shadow: 0 0 10px var(--primary);
  margin-bottom: 1rem;
}

.logo-success::before {
  content: "";
  height: 2rem;
  width: 3.5rem;
  border-left: 5px solid var(--lightPrimary2);
  border-bottom: 5px solid var(--lightPrimary2);
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0.8;
  transform: translate(-50%, -60%) rotate(-45deg);
  transform-origin: center center;
}

@media screen and (max-width: 1024px) {
  .main-submitPage {
    width: 92%;
    padding: 0;
  }
}

@media screen and (max-width: 480px) {
  .main-submitPage {
    width: 88%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/submitStatus/submitStatus.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,qBAAqB;EACrB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;;EAEnB,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,aAAa;EACb,kBAAkB;EAClB,mCAAmC;EACnC,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,2CAA2C;EAC3C,6CAA6C;EAC7C,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,YAAY;EACZ,+CAA+C;EAC/C,+BAA+B;AACjC;;AAEA;EACE;IACE,UAAU;IACV,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".container-submitPage {\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  color: var(--text);\n  font-size: 2rem;\n}\n\n.main-submitPage {\n  width: 70%;\n  height: 50%;\n  border-radius: 1.5rem;\n  padding: 3rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  gap: 1rem;\n}\n\n.main-submitPage p {\n  text-align: center;\n  font-size: 1.5rem;\n}\n\n.logo-success {\n  position: relative;\n  content: \"\";\n  width: 10rem;\n  height: 10rem;\n  padding: 1rem;\n  border-radius: 50%;\n  box-shadow: 0 0 10px var(--primary);\n  margin-bottom: 1rem;\n}\n\n.logo-success::before {\n  content: \"\";\n  height: 2rem;\n  width: 3.5rem;\n  border-left: 5px solid var(--lightPrimary2);\n  border-bottom: 5px solid var(--lightPrimary2);\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  opacity: 0.8;\n  transform: translate(-50%, -60%) rotate(-45deg);\n  transform-origin: center center;\n}\n\n@media screen and (max-width: 1024px) {\n  .main-submitPage {\n    width: 92%;\n    padding: 0;\n  }\n}\n\n@media screen and (max-width: 480px) {\n  .main-submitPage {\n    width: 88%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
