// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-button {
  margin-top: 1rem;
  flex: 1 1;
  width: 10rem;
  display: flex;
  align-items: flex-end;
}

.container-button button {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.7rem 1.2rem;
  border-radius: 1.5rem;
  background: var(--primary);
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  letter-spacing: 2px;
}

.container-button button:hover {
  opacity: 0.9;
}

.container-button button:active {
  scale: 0.98;
  opacity: 0.7;
}
`, "",{"version":3,"sources":["webpack://./src/components/buttonNext/buttonNext.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,SAAO;EACP,YAAY;EACZ,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,wBAAmB;EAAnB,mBAAmB;EACnB,sBAAsB;EACtB,qBAAqB;EACrB,0BAA0B;EAC1B,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".container-button {\n  margin-top: 1rem;\n  flex: 1;\n  width: 10rem;\n  display: flex;\n  align-items: flex-end;\n}\n\n.container-button button {\n  width: 100%;\n  height: fit-content;\n  padding: 0.7rem 1.2rem;\n  border-radius: 1.5rem;\n  background: var(--primary);\n  color: white;\n  font-size: 1.2rem;\n  font-weight: 500;\n  cursor: pointer;\n  transition: all 0.1s ease-in-out;\n  letter-spacing: 2px;\n}\n\n.container-button button:hover {\n  opacity: 0.9;\n}\n\n.container-button button:active {\n  scale: 0.98;\n  opacity: 0.7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
