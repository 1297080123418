import React, { memo, useCallback, useState } from "react";
import "./rangeSlider.css";

const RangeSlider = ({ handleInputChange, alertPrice }) => {
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();

  const VND = Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });

  const handleMinChange = useCallback(
    (e) => {
      const value = Number(e.target.value);
      setMinValue(value);
      handleInputChange("min_prices", value);
    },
    [handleInputChange]
  );

  const handleMaxChange = useCallback(
    (e) => {
      const value = Number(e.target.value);
      setMaxValue(value);
      handleInputChange("max_prices", value);
    },
    [handleInputChange]
  );

  // const handlePriceRange = useCallback(
  //   (e) => {
  //     e.stopPropagation();
  //     e.preventDefault();
  //     if (maxValue && minValue && maxValue > minValue && minValue >= 0) {
  //       handleInputChange("min_prices", minValue);
  //       handleInputChange("max_prices", maxValue);
  //       setAlertPrice(false);
  //     } else {
  //       setAlertPrice(true);
  //     }
  //   },
  //   [minValue, maxValue, handleInputChange]
  // );

  return (
    <div className="range-slider-container">
      <h2>
        Giá tham khảo: {VND.format(minValue || 1000000)} -{" "}
        {VND.format(maxValue || 30000000)}
      </h2>

      <div className="range-values">
        <input
          type="number"
          onChange={handleMinChange}
          placeholder="₫ Từ"
          min="0"
          value={minValue || ""}
        />
        <img src="icon-line.svg" alt="line icon" />
        <input
          type="number"
          placeholder="₫ Đến"
          onChange={handleMaxChange}
          value={maxValue || ""}
        />
      </div>
      {alertPrice && <p>* Vui lòng điền khoảng giá phù hợp</p>}
      {/* <button
        className="button-apply-prices"
        disabled={!minValue || !maxValue}
        onClick={handlePriceRange}
      >
        Áp dụng
      </button> */}
    </div>
  );
};

export default memo(RangeSlider);
