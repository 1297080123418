import { combineReducers, legacy_createStore as createStore } from "redux";
import onboardReducer from "./reducers/onboard";

const rootReducer = combineReducers({
  onboard: onboardReducer,
});

let Store = createStore(rootReducer);

export default Store;
