export const InforInput = [
  {
    title: "Tên Studio",
    placeholder: "Nhập tên Studio",
    key: "name",
    type: "text",
    require: true,
  },
  {
    title: "Số điện thoại",
    placeholder: "Nhập SĐT",
    key: "mobile",
    type: "number",
    require: true,
  },
  {
    title: "Mã giới thiệu (nếu có)",
    placeholder: "Nhập mã giới thiệu",
    key: "referral",
    type: "number",
  },
];

export const PriceInput = [
  {
    title: "Giá tối thiểu",
    placeholder: "Nhập giá tối thiểu",
    key: "min_price",
    type: "text",
    require: true,
  },
  {
    title: "Giá tối đa",
    placeholder: "Nhập giá tối đa",
    key: "max_price",
    type: "text",
    require: true,
  },
];

export const DetailsInput = [
  {
    title: "Email (nếu có)",
    placeholder: "Nhập Email",
    key: "email",
    type: "email",
    require: true,
  },
  {
    title: "Địa chỉ",
    placeholder: "Số nhà, tên đường",
    key: "address",
    type: "text",
    require: true,
  },
];

export const storeId = "be171ff1-074d-4a12-bdd1-576add4327fa";
