import React, { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import "./header.css";
import { arrNameSteps } from "../../constants/data-stepBar";

const Header = ({ step }) => {
  const navigate = useNavigate();

  const handleBackHomePage = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const RenderStepBar = useCallback(() => {
    return (
      <ul className="step-wizard-list">
        {arrNameSteps.map((item, index) => (
          <li
            className={`step-wizard-item ${
              index + 1 === step ? "current-item" : ""
            }`}
            key={index}
          >
            <span className="progress-count">{index + 1}</span>
            <span className="progress-label">{item}</span>
          </li>
        ))}
      </ul>
    );
  }, [step]);
  return (
    <div className="container-header">
      <button onClick={handleBackHomePage}>
        <img className="logo-home" src="icon-weddingo.png" alt="iconHome" />
      </button>
      <div className="step-wizard">{RenderStepBar()}</div>
    </div>
  );
};

export default memo(Header);
