import { types } from "../../actions/onboard";

const onboardReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.UPDATE_INFOR: {
      return { ...state, dataInfor: payload };
    }
    case types.UPDATE_DETAILS: {
      return { ...state, dataDetails: payload };
    }
    case types.UPDATE_BANNER: {
      return { ...state, imageBanner: payload };
    }
    case types.UPDATE_COLLECTIONS: {
      return { ...state, dataCollections: payload };
    }
    default:
      return state;
  }
};

export default onboardReducer;
