import { memo } from "react";
import styles from "./styles.module.css";

const DeleteAccountGuide = () => {
  return (
    <div className={styles.container}>
      <h1>Các bước để xóa tài khoản</h1>
      <br />
      <h2>1. Đăng nhập bằng tài khoản mạng xã hội Google/Apple</h2>
      <img src="./delete-account-guide-1.PNG" className={styles.image_guide} />
      <br />
      <h2>2. Nhấn "Cài đặt" ở tab cài đặt</h2>
      <br />
      <h2>3. Nhấn "Cài đặt khác"</h2>
      <img src="./delete-account-guide-2.PNG" className={styles.image_guide} />
      <br />
      <h2>4. Nhấn "Xóa tài khoản"</h2>
      <img src="./delete-account-guide-3.PNG" className={styles.image_guide} />
      <br />
      <h2>5. Nhấn Xóa</h2>
      <img src="./delete-account-guide-4.PNG" className={styles.image_guide} />
      <br />
      <p>
        Bằng việc nhấn xóa, tất cả các dữ liệu liên quan đến tài khoản của bạn
        (danh mục đám cưới, thông tin cá nhân, ...) sẽ không thể truy cập được.
        Dữ liệu sẽ bị xóa, nếu bạn muốn khôi phục lại, hãy liên hệ với chúng tôi
        qua nguyenduythang811@gmail.com
      </p>
    </div>
  );
};

export default memo(DeleteAccountGuide);
