import React, { memo, useCallback } from "react";
import "./selectionInput.css";

const SelectionInput = ({
  tagName,
  dataLocation,
  keyValue,
  currentInputValue,
  setCurrentInputValue,
  disabled,
}) => {
  const handleChange = useCallback(
    (event) => {
      const value = event.target.value;
      setCurrentInputValue(value);
    },
    [setCurrentInputValue]
  );

  const RenderListOption = useCallback(() => {
    return (
      <select
        className={keyValue}
        id={keyValue}
        onChange={handleChange}
        value={currentInputValue}
        disabled={disabled}
      >
        <option value="">{tagName}</option>
        {dataLocation.map(
          ({ [`${keyValue}_name`]: name, [`${keyValue}_id`]: id }) => (
            <option id={id} key={id} value={id}>
              {name}
            </option>
          )
        )}
      </select>
    );
  }, [
    dataLocation,
    keyValue,
    handleChange,
    currentInputValue,
    disabled,
    tagName,
  ]);

  return (
    <div className="container-selection-input" key={keyValue}>
      <h2>{tagName}</h2>
      <div className="selection-input">
        {RenderListOption()}
        <img
          src="/icon-dropdown.svg"
          className={`icon-dropdown ${disabled ? "icon-disable" : ""}`}
          alt="Dropdown Icon"
        />
      </div>
    </div>
  );
};

export default memo(SelectionInput);
