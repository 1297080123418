// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-field-input {
  width: 100%;
}

.container-field-input h2 {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: var(--text);
  letter-spacing: 1px;
  margin-left: 1rem;
}

.container-field-input input {
  background: linear-gradient(
    to right,
    rgba(212, 212, 212, 0.4) 50%,
    rgba(210, 210, 210, 0.4)
  );
  width: 100%;
  border-radius: 5rem;
  padding: 1rem 1.4rem;
  color: var(--text);
  font-weight: 500;
  font-size: 1rem;
  border: transparent;
  white-space: pre-wrap;
  text-overflow: scroll;
  outline: none;
}

.container-field-input input::placeholder {
  color: rgb(185, 185, 185);
}
`, "",{"version":3,"sources":["webpack://./src/components/fieldInput/fieldInput.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE;;;;GAIC;EACD,WAAW;EACX,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,qBAAqB;EACrB,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".container-field-input {\n  width: 100%;\n}\n\n.container-field-input h2 {\n  font-size: 1.2rem;\n  font-weight: 500;\n  margin-bottom: 0.5rem;\n  color: var(--text);\n  letter-spacing: 1px;\n  margin-left: 1rem;\n}\n\n.container-field-input input {\n  background: linear-gradient(\n    to right,\n    rgba(212, 212, 212, 0.4) 50%,\n    rgba(210, 210, 210, 0.4)\n  );\n  width: 100%;\n  border-radius: 5rem;\n  padding: 1rem 1.4rem;\n  color: var(--text);\n  font-weight: 500;\n  font-size: 1rem;\n  border: transparent;\n  white-space: pre-wrap;\n  text-overflow: scroll;\n  outline: none;\n}\n\n.container-field-input input::placeholder {\n  color: rgb(185, 185, 185);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
