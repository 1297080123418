import React, { memo } from "react";

import "./storeDetails.css";
import FormDataDetails from "./formDataDetails";
import Header from "../../components/header";
import Footer from "../../components/footer";

const StoreDetails = () => {
  return (
    <div className="container-storedetails">
      <Header step={3} />
      <div className="main-storedetails">
        <FormDataDetails />
      </div>
      <Footer />
    </div>
  );
};

export default memo(StoreDetails);
