// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__EUkQ0 {
  padding: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/privacyPolicy/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf","sourcesContent":[".container {\n  padding: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__EUkQ0`
};
export default ___CSS_LOADER_EXPORT___;
