import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./verificationPage.css";
import Header from "../../components/header";
import Footer from "../../components/footer";

import { useNavigate } from "react-router-dom";

const VerificationPage = () => {
  const { dataInfor } = useSelector((state) => state.onboard);
  const [maskedNumber, setMaskedNumber] = useState();
  const [verificationCode, setVerificationCode] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const last4Digits = dataInfor.mobile.slice(-4);
    const maskedNumber = last4Digits.padStart(dataInfor.mobile.length, "*");
    setMaskedNumber(maskedNumber);
  }, [dataInfor.mobile]);

  const handleChangeCode = useCallback((e) => {
    setVerificationCode(e.target.value);
  }, []);

  const handleVerificaiton = useCallback(() => {
    if (verificationCode) {
      navigate("/store-details");
    }
  }, [navigate, verificationCode]);
  return (
    <div className="container-verificationPage">
      <Header step={2} />
      <div className="main-verificationPage">
        <div className="form-verification">
          <h1>Xác thực số điện thoại</h1>
          <p>{`Mã xác thực đã được gửi tới ${maskedNumber}`}</p>
          <p>Vui lòng nhập mã xác thực đã được gửi đến số điện thoại của bạn</p>
          <input
            type="number"
            placeholder="Nhập mã"
            value={verificationCode}
            onChange={handleChangeCode}
          />

          <button id="button-verification" onClick={handleVerificaiton}>
            Xác thực
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default VerificationPage;
