// AddCollectionBox.js
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import ImageInput from "../../components/imageInput";

import "./storeCollections.css";

const AddCollectionBox = ({
  setListImagesAdd,
  listImagesAdd,
  setTitleCollection,
  titleCollection,
  handleAddCollection,
}) => {
  const { dataCollections = [] } = useSelector((state) => state.onboard);

  const handleTitleChange = useCallback(
    (e) => {
      setTitleCollection(e);
    },
    [setTitleCollection]
  );

  return (
    <div
      className={`box-dataCollectionInput ${
        dataCollections.length < 3 ? "" : "disabledDiv"
      }`}
    >
      <input
        type="text"
        placeholder="Tiêu đề của Bộ sưu tập"
        value={titleCollection}
        onChange={(e) => handleTitleChange(e.target.value)}
      />
      <ImageInput
        page="collection"
        fileImg={listImagesAdd}
        setListImagesAdd={setListImagesAdd}
      />
      <button
        id="buttonAddCollection"
        disabled={!titleCollection || !listImagesAdd[0]}
        onClick={handleAddCollection}
      >
        <img src="icon-add.svg" alt="icon-add" />
        Thêm
      </button>
    </div>
  );
};

export default AddCollectionBox;
